html,
body,
#root,
#root>div {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #eee !important;
}

main.makeStyles-content-8 {
  background-color: #eee;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiTextField-root {
  margin-top: 0px !important;
}

.MuiDrawer-root .MuiGrid-item>div {
  margin-top: 5px !important;
}

.MuiDrawer-root .MuiGrid-item>div>.MuiFormControlLabel-root {
  margin: 10px !important;
}

.Mui-disabled-button {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}

@keyframes flashing {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

.MuiDialogTitle-root {
  text-align: center;
}

@font-face {
  font-family: 'Orbitron';
  src: local('Orbitron'), url(./_assets/fonts/Orbitron-SemiBold.ttf) format('truetype');
}

.MuiFormControl-root {
  width: 100% !important;
}

.MuiPaper-root {
  padding: 10px;
  margin-bottom: 20px;
}

h2 {
  text-align: center;
  font-family: 'Orbitron';
  margin-top: 10px;
  margin-bottom: 10px;
}

h3,
h4,
h5 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

p {
  text-align: center;
  font-family: 'Orbitron';
}

/* h4 {
  text-align: center;
  font-family: 'Orbitron';
  margin-top: 10px;
  margin-bottom: 10px;
} */

button {
  font-family: 'Orbitron';

}

.MuiFormControl-root {
  margin-bottom: 7px !important;
}

#installInstructions {
  display: none
}

@media (display-mode: browser) {
  #installInstructions {
    display: block
  }
}